import "./App.css";
import AboutMe from "./components/aboutMe";
import SampleProject from "./components/featuresProject";
import ListAllItem from "./components/listAllItem";
import PersonalInfo from "./components/personalInfo";

function App() {
  return (
    <>
      <div className="container">
        <PersonalInfo />
        <AboutMe />
        <SampleProject />
        <ListAllItem />

        <div className="footer">
          <p>Develop Using React.js | putranaaim</p>
        </div>

      </div>
    </>
  );
}

export default App;
