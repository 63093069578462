import React from "react";
import Card from "./card";
import Badge from "./badge";
import { FaComputerMouse, FaHourglassStart } from "react-icons/fa6";
import ProgressBar from "./progressBar";
import Counter from "./counter";

function AboutMe() {
  return (
    <>
      <Card
        className={"secondSection"}
        children={
          <div className="cardItem">
            <div className="cardInnerItem">
              <div className="left">
                <Badge
                  text={"About Me"}
                  className={"badgeNoneBG"}
                  icon={<FaHourglassStart />}
                />
                <p className="headSmall">Litle About Me</p>
                <p className="text">
                  I am an enthusiastic and diligent professional with a passion
                  for design and technology. Possessing strong leadership
                  qualities and a robust work ethic, I thrive in team
                  environments and excel under pressure. My ability to innovate
                  and push boundaries ensures continuous improvement and
                  exceptional results.
                </p>
                <div className="counterContainer">
                  <Counter
                    count={"07"}
                    className="counterItem"
                    label={"Years of Experience"}
                  />
                  <Counter
                    count={"63"}
                    className="counterItem"
                    label={"Projects Completed"}
                  />
                  <Counter
                    count={24}
                    className="counterItem"
                    label={"Happy Clients"}
                  />
                </div>
                <Badge
                  text={"List of Tools"}
                  className={"badgeNoneBG"}
                  icon={<FaComputerMouse />}
                />
                <p className="headSmall">
                  Tools and Technologies I Have Utilized
                </p>
                <div className="skillList">
                  <Badge text={"React.js"} />
                  <Badge text={"Node.js"} />
                  <Badge text={"GitHub"} />
                  <Badge text={"SQL"} />
                  <Badge text={"Wordpress"} />
                  <Badge text={"Javascript,"} />
                  <Badge text={"Typescript"} />
                  <Badge text={"CSS"} />
                  <Badge text={"Html"} />
                  <Badge text={"Adobe XD"} />
                  <Badge text={"Figma"} />
                  <Badge text={"Illustrator"} />
                  <Badge text={"Photoshop"} />
                  <Badge text={"Blender"} />
                  <Badge text={"Premier pro"} />
                  <Badge text={"After Effect"} />
                  <Badge text={"Amazon S3"} />
                  <Badge text={"Google Cloud"} />
                </div>
              </div>
              <div className="right">
                <Badge
                  text={"My Skillset"}
                  className={"badgeNoneBG"}
                  icon={<FaComputerMouse />}
                />
                <p className="headSmall">My Ability</p>
                <ProgressBar progress={80} max={100} label={"React.js"} />
                <ProgressBar progress={90} max={100} label={"HTML"} />
                <ProgressBar progress={90} max={100} label={"CSS"} />
                <ProgressBar
                  progress={60}
                  max={100}
                  label={"Web Development"}
                />
                <ProgressBar progress={100} max={100} label={"Graphic"} />
                <ProgressBar progress={90} max={100} label={"Photography"} />
                <ProgressBar progress={70} max={100} label={"Videoshoot"} />
              </div>
            </div>
          </div>
        }
      />
    </>
  );
}

export default AboutMe;
