import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import img1 from '../Asset/images/1.jpg';
import img2 from '../Asset/images/2.jpg';
import img3 from '../Asset/images/3.jpg';
import img4 from '../Asset/images/4.jpg';
import img5 from '../Asset/images/5.jpg';
import img6 from '../Asset/images/6.jpg';
import img7 from '../Asset/images/7.jpg';
import img8 from '../Asset/images/8.jpg';
import img9 from '../Asset/images/9.jpg';
import img10 from '../Asset/images/10.jpg';
import img11 from '../Asset/images/11.jpg';
import img12 from '../Asset/images/12.jpg';
import img13 from '../Asset/images/13.jpg';
import img14 from '../Asset/images/14.jpg';
import img15 from '../Asset/images/15.jpg';
import img16 from '../Asset/images/16.jpg';
import img17 from '../Asset/images/17.jpg';
import img18 from '../Asset/images/18.jpg';
import img19 from '../Asset/images/19.jpg';


export default function MasonryImageList() {
  return (
    <Box sx={{ width: "90%",  padding: "1rem", margin: "auto" }}>
      <ImageList variant="masonry" cols={3} gap={8}>
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.img}?w=248&fit=crop&auto=format`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
}

const itemData = [
  {
    img: img1,
  },
  {
    img: img2,
  },
  {
    img: img3,
  },
  {
    img: img4,
  },
  {
    img: img5,
  },
  {
    img: img6,
  },
  {
    img: img7,
  },
  {
    img: img8,
  },
  {
    img: img9,
  },
  {
    img: img10,
  },
  {
    img: img11,
  },
  {
    img: img12,
  },
  {
    img: img13,
  },
  {
    img: img14,
  },
  {
    img: img15,
  },
  {
    img: img16,
  },
  {
    img: img17,
  },
  {
    img: img18,
  },
  {
    img: img19,
  },


];