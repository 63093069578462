import React from "react";

function Badge({ text, className, icon }) {
  return (
    <div className={`badge ${className}`}>
      {icon} <p>{text}</p>
    </div>
  );
}

export default Badge;
