import React, { useEffect, useState } from "react";
import Card from "../components/card";
import myImage from "../Asset/mypicture.png";
import Role from "./role";
import Badge from "./badge";
import { BsCheckCircleFill } from "react-icons/bs";
import {
  FaBook,
  FaBuildingCircleCheck,
  FaCakeCandles,
  FaLocationDot,
  FaPen,
  FaSquareWhatsapp,
  FaStreetView,
  FaUserGraduate,
} from "react-icons/fa6";
import Button from "@mui/material/Button";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailIcon from "@mui/icons-material/Mail";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

function PersonalInfo() {
  const sendEmail = () => {
    window.location.href = "mailto:axwan.putranaaim@gmail.com";
  };
  const sendWhatsApp = () => {
    window.open("https://wa.link/mo9eev");
  };
  const goLinkedIn = () => {
    window.open("https://www.linkedin.com/in/putra-naaim-3919111a5/");
  };

  return (
    <Card
      className={"firstSection"}
      children={
        <div className="cardItem">
          <div className="avatar">
            <img src={myImage} alt="" />
          </div>
          <div className="nameSection">
            <p className="nameHeader">Putra Naaim</p>
            <p>
              Im a <Role />
            </p>
            <Badge
              text={"Available for Work"}
              className={"badgeAvailable"}
              icon={<BsCheckCircleFill />}
            />
          </div>
          <span className="separator">.</span>
          <div className="skillsContainer">
            <Badge
              text={"Malaysia"}
              className={"badgeTheme"}
              icon={<FaLocationDot />}
            />
            <Badge
              text={"English / Malay"}
              className={"badgeTheme"}
              icon={<FaBook />}
            />
            <Badge
              text={"Frontend Developer"}
              className={"badgeTheme"}
              icon={<FaPen />}
            />
            <Badge
              text={"Optivolve Group"}
              className={"badgeTheme"}
              icon={<FaBuildingCircleCheck />}
            />
            <Badge
              text={"UiTM"}
              className={"badgeTheme"}
              icon={<FaUserGraduate />}
            />
            <Badge
              text={"Male"}
              className={"badgeTheme"}
              icon={<FaStreetView />}
            />
            <Badge
              text={"12 - APR - 1994"}
              className={"badgeTheme"}
              icon={<FaCakeCandles />}
            />
          </div>
          <div className="btnSectionOneContainer">
            <Button
              className="buttonSectionOne"
              onClick={sendWhatsApp}
              startIcon={<WhatsAppIcon />}
            >
              WhatsApp Me
            </Button>
            <Button
              className="buttonSectionOne"
              onClick={sendEmail}
              startIcon={<MailIcon />}
            >
              Email Me
            </Button>
            <Button
              className="buttonSectionOne"
              onClick={goLinkedIn}
              startIcon={<LinkedInIcon />}
            >
              My LinkedIn
            </Button>
          </div>
        </div>
      }
    />
  );
}

export default PersonalInfo;
