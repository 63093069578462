import React from "react";  

const Counter = ({ count, className, label, icon }) => {
    return (
        <div className={`counter ${className}`}>
            <p>{count}</p>
            <div className="labelContainer">
                {icon}{label}
            </div>
        </div>
    );
};

export default Counter;