import React from "react";
import MasonryImageList from "./masonryImageList";

function ListAllItem() {
    return (
        <div className="listAllItem">
            <p className="headSmall">Sample Showcase</p>
            <MasonryImageList />
        
        </div>
    );
}       


export default ListAllItem;