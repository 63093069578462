import React from "react";
import qirs from "../Asset/qirs.png";

function SampleProject() {
  function handleClickQirs() {
    window.open("https://qirs.axstudio.xyz/", "_blank");
  }

  return (
    <div className="listAllItem">
      <p className="headSmall">Sample Project</p>
      <div className="sampleProjectImgContainer">
        <img src={qirs} alt="" onClick={handleClickQirs} />
      </div>
      <div className="smapleProjectDesc">
        <h1>QIRS (Quotation Invoice Receipt System) Features</h1>
        <p>
          <b>Document Management</b>: Quickly create and manage quotations,
          invoices, and receipts, with easy PDF export for professional, branded
          documents.
        </p>
        <p>
          <b>Sales Tracking</b>: Monitor all sales activities and trends with
          comprehensive tracking and analytics, ensuring you stay on top of your
          business performance.
        </p>

        <p>
          <b>Customer Management</b>: Keep a well-organized database of customer
          information and sales history, allowing for better client interactions
          and relationship management.
        </p>
        <p>
          <b>User-Friendly Interface</b>: Enjoy a simple, intuitive design
          that’s accessible from any device, making document and sales
          management easy and efficient.
        </p>
      </div>
    </div>
  );
}

export default SampleProject;
