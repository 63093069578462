import React from "react";

const ProgressBar = ({ progress, max, label }) => {
  const percentage = (progress / max) * 100;

  return (
    <div className="progressBar">
      <p>{label}</p>

      <div className="progress-bar-container">
        <div className="progress-bar" style={{ width: `${percentage}%` }}></div>
      </div>
      <h1>{progress}%</h1>
    </div>
  );
};

export default ProgressBar;
